import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

import "./imprint.scss";

const Imprint = () => {
  return (
    <Layout>
      <Seo title="Impressum"></Seo>
      <section className="container">
        <div className="section-title">
          <h2>Impressum</h2>
        </div>
        <div className="imprint">
          <div className="company">Jautzke Augenoptik</div>
          <div className="company-desc">Inhaber: Michael Jautzke</div>
          <div className="adress-imprint">
            <div className="address">
              <div>Rudower Str. 15</div>
              <div>12351 Berlin</div>
              <div className="spacer"></div>
              <div className="table">
                <div className="key">Telefon</div>
                <div className="value">
                  <a href="tel:03060257834">030 60257834</a>
                </div>
              </div>
              <div className="table">
                <div className="key">Fax</div>
                <div className="value">030 66620424</div>
              </div>
              <div className="table">
                <div className="key">E-Mail</div>
                <div className="value">
                  <a
                    className="padding-r"
                    href="mailto:info@jautzke-augenoptik.de"
                  >
                    info@jautzke-augenoptik.de
                  </a>
                </div>
              </div>
              <div className="spacer"></div>
              <div className="table">
                <div className="key">Titel</div>
                <div className="value">
                  Augenoptikermeister (verliehen in Deutschland)
                </div>
              </div>
              <div className="table">
                <div className="key">USt-IdNr.</div>
                <div className="value">DE 814516699</div>
              </div>
            </div>
            <div className="imprint_info">
              <div className="key">Zuständige Kammer</div>
              <div className="value">
                Handwerkskammer Berlin, Blücherstr. 68, 10961 Berlin
              </div>
              <div className="spacer"></div>
              <div className="key">Zuständige Innung</div>
              <div className="value">
                Augenoptiker-Innung Berlin, Apostel-Paulus-Str. 12, 10825 Berlin{" "}
                <br />
                Berufsrechtliche Regelungenfestgehalten in der Handwerksordnung
              </div>
            </div>
          </div>
          <div className="spacer"></div>
          <div className="key">Haftungsausschluss</div>
          <div className="value">
            Für die auf unserer Internetseite vorhanden externen Links
            übernehmen wir trotz sorgfältiger und regelmäßiger Überprüfung keine
            Haftung. Für den Inhalt der externen Seiten sind ausschließlich
            deren Betreiber verantwortlich.
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Imprint;
